<template>
  <v-card flat tile color="blue-grey darken-3">
    <v-card-text>
      <v-row justify="end">
        <v-col cols="12" lg="6" xl="4">
          <v-text-field
            single-line
            hide-details
            autocomplete="off"
            v-model="search"
            outlined
            placeholder="Поиск"
            append-icon="mdi-magnify"
          />
        </v-col>
      </v-row>
      <v-row v-if="items">
        <v-col
          :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl"
          v-for="(item, i) in items"
          :key="item.id"
        >
          <slot
            :item="item"
            :index="i"
            :del="del"
          />
        </v-col>
        <v-col :cols="cols" :sm="sm" :md="md" :lg="lg" :xl="xl">
          <v-container fill-height>
            <v-row class="justify-center align-center" dense>
              <v-col
                cols="12"
                class="text-center"
              >
                <v-btn
                  fab outlined
                  @click="addItem"
                  elevation="1"
                  color="blue-grey lighten-2"
                >
                  <v-icon large>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
        </v-container>
        </v-col>
      </v-row>
      <v-row v-if="total > 1">
        <v-col cols="12">
          <v-pagination
            v-model="page"
            :length="total"
            color="blue-grey lighten-2"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

const paginate = 12
export default {
  props: {
    index: {
      type: Function
    },

    destroy: {
      type: Function
    },

    emptyTemplate: {
      type: Object,
      default: () => ({})
    },

    cols: {
      type: Number,
      default: 12
    },

    sm: {
      type: Number,
      default: null
    },

    md: {
      type: Number,
      default: 6
    },

    lg: {
      type: Number,
      default: null
    },

    xl: {
      type: Number,
      default: 4
    }
  },

  data: () => ({
    items: [],
    page: 1,
    search: '',
    total: null
  }),

  mounted () {
    this.getItems()
  },

  methods: {
    ...mapActions({
      setMessage: 'app/setSystemMessage'
    }),

    getItems () {
      this.index({
        params: {
          page: this.page,
          search: this.search,
          paginate: paginate
        }
      }).then(response => {
        this.items = response.data
        this.total = Math.ceil(response.total / paginate)
      })
    },

    addItem () {
      this.items.push(JSON.parse(JSON.stringify(this.emptyTemplate)))
      console.log(this.items)
    },

    del (item) {
      if (item.id) {
        this.destroy({ item: item }).then((response) => {
          this.items = this.items.filter(i => i !== item)
          this.setMessage({
            message: 'Успешно удалено',
            timout: 3000,
            color: 'success',
            icon: 'mdi-check'
          })
        }).catch((e) => {
          this.setMessage({
            message: 'Произошла непредвиденная ошибка',
            color: 'error',
            icon: 'mdi-alert-octagon-outline'
          })
        })
      } else {
        this.items = this.items.filter(i => i !== item)
      }
    }
  },

  watch: {
    page () {
      this.getItems()
    },
    search () {
      this.getItems()
    }
  }
}
</script>

<style>
.theme--dark.v-pagination .v-pagination__item,
.theme--dark.v-pagination .v-pagination__navigation {
  background: transparent !important;
  color: #90A4AE !important;
  border: 1px solid #90A4AE;
}

.theme--dark.v-pagination .v-pagination__item--active {
    background: #90A4AE !important;
    color: #37474F !important;
}

.theme--dark.v-pagination .v-pagination__navigation .v-icon {
  color: #90A4AE !important;
}
</style>
