<template>
  <v-card color="blue-grey darken-4" style="height: 100%">
    <v-toolbar
      color="blue-grey darken-4"
      flat
      :dense="!errors[title.key]"
    >
      <v-toolbar-title class="text-h5 white--text pl-0">
        <v-text-field
          class="borderless input-header"
          :placeholder="title.title"
          :disabled="disabled"
          autocomplete="off"
          :hide-details="!errors[title.key]"
          v-model="item[title.key]"
          :error-messages="errors[title.key]"
        ></v-text-field>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn
        color="success"
        small
        icon
        :disabled="disabled || !changed "
        @click="save"
      >
        <v-icon small>mdi-check</v-icon>
      </v-btn>

      <v-btn
        color="error"
        class="ml-2"
        :disabled="disabled"
        small
        @click="confirmDelete = true"
        icon
      >
        <v-icon small>mdi-delete-outline</v-icon>
      </v-btn>
    </v-toolbar>

    <confirm-action
      v-model="confirmDelete"
      :message="'Вы действительно хотите удалить \'' + item[title.key] + '\'?'"
      @confirm="confirm"
    />

    <v-divider />

    <v-card-text class="pt-0">
      <div
        v-for="(field, i) in fields"
        :key="i"
      >
        <v-text-field
          v-if="field.type === 'text'"
          class="borderless"
          :placeholder="field.title"
          :label="field.label"
          autocomplete="off"
          :disabled="disabled || field.disabled"
          v-model="item[field.key]"
          :hide-details="!errors[field.key]"
          :error-messages="errors[field.key]"
        />
        <v-textarea
          v-if="field.type === 'area'"
          class="borderless"
          :class="field.monospace ? 'monospace' : ''"
          :placeholder="field.title"
          autocomplete="off"
          :disabled="disabled || field.disabled"
          v-model="item[field.key]"
          :hide-details="!errors[field.key]"
          :error-messages="errors[field.key]"
        />
        <v-select
          v-if="field.type === 'select'"
          class="borderless"
          :placeholder="field.title"
          autocomplete="off"
          :disabled="disabled || field.disabled"
          :items="field.items"
          v-model="item[field.key]"
          :hide-details="!errors[field.key]"
          :error-messages="errors[field.key]"
        />
        <v-autocomplete
          v-if="field.type === 'autocomplete'"
          class="borderless"
          :placeholder="field.title"
          autocomplete="off"
          :disabled="disabled || field.disabled"
          :items="field.items"
          v-model="item[field.key]"
          :hide-details="!errors[field.key]"
          :error-messages="errors[field.key]"
        />
      </div>
      <slot
        :item="item"
        :errors="errors"
        :extChange="extChange"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import ConfirmAction from '@/components/Common/ConfirmAction'
import { mapActions } from 'vuex'

export default {
  components: {
    ConfirmAction
  },

  props: {
    fields: {
      type: Array,
      default: null
    },

    item: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    store: { type: Function },
    update: { type: Function }
  },

  data: () => ({
    changed: false,
    confirmDelete: false,
    errors: {}
  }),

  methods: {
    ...mapActions({
      setMessage: 'app/setSystemMessage'
    }),

    afterMarkedChanged () {},

    confirm (confirm) {
      if (confirm) {
        this.$emit('delete')
      }
      this.confirmDelete = false
    },

    extChange () {
      this.changed = true
    },

    markChanged () {
      this.changed = true
      this.afterMarkedChanged()
    },

    save () {
      const method = this.item.id ? 'update' : 'store'
      this.errors = {}

      this[method]({
        params: this.item
      }).then(response => {
        for (const key in response) {
          this.item[key] = response[key]
        }

        this.setMessage({
          message: 'Данные сохранены',
          timout: 3000,
          color: 'success',
          icon: 'mdi-check'
        })

        this.changed = false
      }).catch(e => {
        this.setMessage({
          message: e.response.status === 422 ? 'Некорректные данные' : 'Произошла непредвиденная ошибка',
          timout: 3000,
          color: 'error',
          icon: 'mdi-alert-octagon-outline'
        })
        this.errors = e.response.data.errors
      })
    }
  },

  watch: {
    item: {
      deep: true,
      handler () {
        this.changed = true
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'))
        }, 10)
      }
    }
  },

  computed: {
    title () {
      return this.fields.find(field => field.type === 'title')
    }
  }
}
</script>

<style>
.borderless .v-input__slot::before {
  border-style: none !important;
}

.monospace {
  font-family: monospace, monospace;
}

</style>
