<template>
<v-row dense>
  <v-col cols="2" v-if="value.vis_props && value.vis_props.icon">
    <img class="mx-1 my-4 device-group-icon" :src="value.vis_props.icon" />
  </v-col>
  <v-col :cols="value.vis_props && value.vis_props.icon ? 10 : 12">
    <v-file-input
      class="borderless"
      chips
      small-chips
      truncate-length="15"
      placeholder="Пиктограмма"
      :error-messages="errors['vis_props.icon_file']"
      @change="handleImages"
    ></v-file-input>
  </v-col>
</v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object
    },

    errors: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    handleImages (e) {
      this.createBase64Image(e)
    },

    createBase64Image (image) {
      const reader = new FileReader()

      reader.readAsDataURL(image)

      reader.onload = e => {
        this.value.vis_props.icon_file = e.target.result
        this.$emit('input')
      }
    }
  }
}
</script>

<style>
.device-group-icon {
  background-color: #fff;
  border-radius: 6px 12px 6px 12px;
}
</style>
