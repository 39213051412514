<template>
  <v-dialog
    :value="value"
    persistent
    max-width="350"
  >
    <v-card color="blue-grey darken-3">
      <v-card-title color="red darken-3">Подтвердите действие</v-card-title>
      <v-divider />
      <v-card-text class="mt-5">{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="blue-grey lighten-3"
          outlined
          @click="$emit('confirm', true)"
        >
          Да
        </v-btn>
        <v-btn
          color="blue-grey lighten-3"
          outlined
          @click="$emit('confirm', false)"
        >
          Нет
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: true
    },
    message: {
      type: String
    }
  }
}
</script>

<style>

</style>
